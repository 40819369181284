.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #ff9966 0%, #ff5e62 100%);
  height: 100vh;
}
.header {
  padding: 20px;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 20px 30px;
  background-color: #f1f1f1;
}

.password {
  display: flex;
  flex-direction: column;
}

.valid {
  color: green;
}

.invalid {
  color: red;
}

.label {
  font-size: 20px;
  font-weight: bold;
}
.input-field {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  box-sizing: border-box;
}
